import { useState, useEffect } from 'react';
import axios from 'axios';

// Function to handle form changes
export const handleChange = (e, setFormData) => {
  const { name, value } = e.target;
  setFormData((prevData) => ({
    ...prevData,
    [name]: value,
  }));
};

// Function to submit forms and process the response
export const handleSubmit = async (url, formData, setGeneratedText, setLoading) => {
  setLoading(true);
  try {
    const response = await axios.post(url, formData);
    setGeneratedText(response.data);
  } catch (error) {
    console.error('Error generating text:', error);
  } finally {
    setLoading(false);
  }
};

// Function to copy text to clipboard
export const handleCopyToClipboard = (text) => {
  navigator.clipboard.writeText(text)
    .then(() => {
      console.log('Text copied to clipboard');
    })
    .catch((err) => {
      console.error('Failed to copy text: ', err);
    });
};

// Function to copy plain text to clipboard
export const copyPlainText = (htmlContent) => {
  // Temporäres DOM-Element erstellen
  const tempElement = document.createElement('div');
  tempElement.innerHTML = htmlContent;
  
  // Zeilenumbrüche für Block-Elemente hinzufügen
  const blockElements = tempElement.querySelectorAll('p, div, li, h1, h2, h3, h4, h5, h6, blockquote');
  blockElements.forEach(element => {
    // Fügt Zeilenumbrüche nach Block-Elementen ein
    element.insertAdjacentHTML('afterend', '\n');
    
    // Spezielle Behandlung für Listenelemente
    if (element.tagName === 'LI') {
      element.insertAdjacentHTML('beforebegin', '• ');
    }
  });

  // Doppelte Zeilenumbrüche für bessere Lesbarkeit
  const lists = tempElement.querySelectorAll('ul, ol');
  lists.forEach(list => {
    list.insertAdjacentHTML('afterend', '\n');
  });

  // Extrahiere den formatierten Text
  const plainText = tempElement.innerText
    .replace(/\n{3,}/g, '\n\n') // Reduziere mehrfache Zeilenumbrüche auf maximal zwei
    .trim();

  // Text in die Zwischenablage kopieren
  navigator.clipboard.writeText(plainText)
    .then(() => {
      console.log('Formatierter Text erfolgreich in die Zwischenablage kopiert.');
    })
    .catch((err) => {
      console.error('Fehler beim Kopieren des formatierten Textes:', err);
    });
};

// Hook to dynamically adjust text size based on length
export const useDynamicTextSize = (text, baseSize = 1.2, minSize = 0.8, thresholds = [1000, 2000]) => {
  const [textSize, setTextSize] = useState(`${baseSize}em`);
  
  useEffect(() => {
    if (text === undefined || text === null) {
      console.log('Warning: text is undefined or null in useDynamicTextSize');
      return;
    }
    
    let newSize = baseSize;
    if (text.length > thresholds[1]) {
      newSize = minSize;
    } else if (text.length > thresholds[0]) {
      newSize = (baseSize - 0.2).toFixed(1);
    }
    setTextSize(`${newSize}em`);
  }, [text, baseSize, minSize, thresholds]);
  
  return textSize;
};

export const useScrollRestoration = () => {
  useEffect(() => {
    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual';
    }
  }, []);
};
// File handling functions
export const handleFileChange = (e, setFile, setFileName) => {
  const file = e.target.files[0];
  setFile(file);
  setFileName(file ? file.name : '');
};

export const handleDragEnter = (e, setDragging) => {
  e.preventDefault();
  setDragging(true);
};

export const handleDragLeave = (e, setDragging) => {
  e.preventDefault();
  setDragging(false);
};

export const handleDragOver = (e) => {
  e.preventDefault();
};

export const handleDrop = (e, setDragging, setFile, setFileName) => {
  e.preventDefault();
  const file = e.dataTransfer.files[0];
  setFile(file);
  setFileName(file ? file.name : '');
  setDragging(false);
};

export const copyFormattedContent = (content, onSuccess, onError) => {
  console.log('1. Kopiervorgang startet mit:', { 
    contentLength: content?.length,
    hasOnSuccess: !!onSuccess,
    hasOnError: !!onError 
  });
  
  const quillEditor = document.querySelector('.ql-editor');
  console.log('2. Quill Editor gefunden:', !!quillEditor, 'mit __quill:', !!quillEditor?.__quill);
  
  if (quillEditor && quillEditor.__quill) {
    const delta = quillEditor.__quill.getContents();
    console.log('3. Delta erhalten:', delta);
    
    let formattedText = '';
    
    delta.ops.forEach(op => {
      if (typeof op.insert === 'string') {
        let text = op.insert;
        
        if (op.attributes) {
          if (op.attributes.list) {
            text = `• ${text}`;
          }
          if (op.attributes.header) {
            text = `${text}\n`;
          }
          if (op.attributes.blockquote) {
            text = `> ${text}\n`;
          }
        }
        
        formattedText += text;
      } else if (op.insert && op.insert.break) {
        formattedText += '\n';
      }
    });

    const cleanText = formattedText
      .replace(/\n{3,}/g, '\n\n')
      .trim();
      
    console.log('4. Formatierter Text erstellt:', cleanText);

    navigator.clipboard.writeText(cleanText)
      .then(() => {
        console.log('5. Text erfolgreich in Zwischenablage kopiert');
        if (onSuccess) {
          console.log('6. onSuccess Callback wird aufgerufen');
          onSuccess();
        }
      })
      .catch(err => {
        console.error('5. Fehler beim Kopieren:', err);
        console.log('Fallback wird verwendet');
        copyPlainText(content);
        if (onError) {
          console.log('6. onError Callback wird aufgerufen');
          onError(err);
        }
      });
  } else {
    console.log('2b. Kein Quill Editor gefunden, verwende Fallback');
    copyPlainText(content);
    if (onSuccess) {
      console.log('3b. onSuccess Callback wird aufgerufen (Fallback)');
      onSuccess();
    }
  }
};

// Neue Funktion für automatisches Scrollen
export const useAutoScroll = (content, isMobile) => {
  useEffect(() => {
    // Prüft ob Content existiert und sich geändert hat
    const hasContent = content?.content && (
      // Für normale BaseForm (String content)
      (typeof content.content === 'string' && content.content.length > 0) ||
      // Für BaseForm_social (Object content)
      (typeof content.content === 'object' && 
       (Array.isArray(content.content) ? 
         content.content.length > 0 : 
         Object.keys(content.content).length > 0))
    );
    
    const shouldScroll = hasContent && content.changed;

    if (isMobile && shouldScroll) {
      const displayContainer = document.querySelector('.display-container');
      if (displayContainer) {
        setTimeout(() => {
          // Berechne Position mit Offset
          const headerHeight = 60; // Ungefähre Höhe des Headers
          const containerRect = displayContainer.getBoundingClientRect();
          const offsetPosition = containerRect.top + window.pageYOffset - headerHeight;

          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
          });
        }, 300);
      }
    }
  }, [content, isMobile]);
};